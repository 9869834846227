import 'assets/css/challenge_main.css';
// import '../assets/css/challenge_start_popup.css'

const ChallengeStartPopup = () => {
    return(

        <div className="share-pop-ui open">
            <span className="dim"></span>
            <div className="dp-table pos-a">
                <div className="dp-cell">
                    <div className="share-alert-box">
                    <div class="btn-close-area">
                        <button class="btn-close">
                            <i class="icon i-pop-close">닫기</i>
                        </button>
                    </div>
                        <div className="share-txt-area">
                            <div className="share-txt">
                                <p>3문제 이상 정답을 맞추면<br />음표를 받을 수 있어요!</p>
                            </div>
                        </div>
                        <div className="share-btn-area">
                            <button className="button-ok clickable">문제풀기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    )
};

export default ChallengeStartPopup;