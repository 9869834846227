import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { urlPath } from 'services';
import {
  IGenerateProblemReq,
  IGenerateProblemRes,
  ILrrCheckRes,
  ILrrResultReq,
  ILrrResultRes,
  IRetryProblemsReq,
  IRetryProblemsRes,
  ISubmitProblemReq,
  ISubmitProblemRes,
} from 'interfaces/problem';

export const lrrApi = createApi({
  reducerPath: 'lrrApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/lrr/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Lrr'],
  endpoints: builder => ({
    checkLrr: builder.query<ILrrCheckRes, void>({
      query: () => 'check',
      providesTags: () => [{ type: 'Lrr', id: 'check' }],
    }),
    generateProblems: builder.query<IGenerateProblemRes, IGenerateProblemReq>({
      query: ({ curriculums }) => `generate/${curriculums.map(value => value)}`,
      providesTags: () => [{ type: 'Lrr', id: 'generate' }],
    }),
    submitLrrProblems: builder.mutation<ISubmitProblemRes, ISubmitProblemReq>({
      query(body) {
        return {
          url: 'submit',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Lrr', id: 'submit' }],
    }),
    lrrResult: builder.mutation<ILrrResultRes, ILrrResultReq>({
      query(body) {
        return {
          url: 'result',
          method: 'POST',
          body,
        };
      },
    }),
    retryProblems: builder.query<IRetryProblemsRes, IRetryProblemsReq>({
      query: ({ yearMonth, lrrCode }) => `retry/${yearMonth}/${lrrCode}`,
      providesTags: () => [{ type: 'Lrr', id: 'retry' }],
    })
  }),
});

export const { useCheckLrrQuery, useLazyGenerateProblemsQuery, useSubmitLrrProblemsMutation, useLrrResultMutation, useLazyRetryProblemsQuery } = lrrApi;
