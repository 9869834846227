import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import logger from 'redux-logger';

import initReducer from 'store/reducers/init';
import userReducer from 'store/reducers/user';
import challengeReducer from 'store/reducers/challenge';
import popupReducer from 'store/reducers/popup';
import adminReducer from 'store/reducers/admin';
import problemsReducer from 'store/reducers/problem';
import globalReducer from 'store/reducers/global';
import { userApi } from 'services/user';
import { registerApi } from 'services/register';
import { mycellApi } from 'services/mycell';
import { studApi } from 'services/stud';
import { periodApi } from 'services/period';
import { missionApi } from 'services/mission';
import { adminApi } from 'services/admin';
import { aloneChallengeApi } from 'services/aloneChallenge';
import { resultApi } from 'services/result';
import { cheerApi } from 'services/cheer';
import { lrrApi } from 'services/lrr';
import { problemApi } from 'services/problem';
import { getLearningResultApi } from 'services/getLearningResult';

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [registerApi.reducerPath]: registerApi.reducer,
  [mycellApi.reducerPath]: mycellApi.reducer,
  [studApi.reducerPath]: studApi.reducer,
  [periodApi.reducerPath]: periodApi.reducer,
  [missionApi.reducerPath]: missionApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [aloneChallengeApi.reducerPath]: aloneChallengeApi.reducer,
  [resultApi.reducerPath]: resultApi.reducer,
  [cheerApi.reducerPath]: cheerApi.reducer,
  [lrrApi.reducerPath]: lrrApi.reducer,
  [problemApi.reducerPath]: problemApi.reducer,
  [getLearningResultApi.reducerPath]: getLearningResultApi.reducer,
  init: initReducer.reducer,
  user: userReducer.reducer,
  challenge: challengeReducer.reducer,
  popup: popupReducer.reducer,
  admin: adminReducer.reducer,
  problems: problemsReducer.reducer,
  global: globalReducer.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      // logger,
      userApi.middleware,
      registerApi.middleware,
      mycellApi.middleware,
      studApi.middleware,
      periodApi.middleware,
      missionApi.middleware,
      adminApi.middleware,
      aloneChallengeApi.middleware,
      resultApi.middleware,
      cheerApi.middleware,
      lrrApi.middleware,
      problemApi.middleware,
      getLearningResultApi.middleware,
    ),
  devTools: process.env.NODE_ENV === 'development',
});

setupListeners(store.dispatch); // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
export type IRootState = ReturnType<typeof rootReducer>;
