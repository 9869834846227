import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IGetStudReq, IGetStudLrnExLogRes } from 'interfaces/stud';
import { proxyUrlPath } from 'services';

export const studApi = createApi({
  reducerPath: 'studApi',
  baseQuery: fetchBaseQuery({
    baseUrl: proxyUrlPath + '/stud/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Stud'],
  endpoints: builder => ({
    getStudLrnExLog: builder.query<IGetStudLrnExLogRes, IGetStudReq>({
      query: ({ studId }) => `getStudLrnExLog/${studId}`,
      providesTags: () => [{ type: 'Stud', id: 'getStudLrnExLog' }],
    }),
  }),
});

export const { useGetStudLrnExLogQuery } = studApi;
