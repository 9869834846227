import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import {
  IRegisterCheckReq,
  IRegisterCheckRes,
  IRegisterMissionReq,
  IRegisterMissionRes,
  IRegisterProtReq,
  IRegisterProtRes,
  IRegisterUserReq,
  IRegisterUserRes,
} from 'interfaces/register';

export const registerApi = createApi({
  reducerPath: 'registerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/register/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Register'],
  endpoints: builder => ({
    user: builder.mutation<IRegisterUserRes, IRegisterUserReq>({
      query(body) {
        return {
          url: 'user',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Register', id: 'user' }],
    }),
    check: builder.query<IRegisterCheckRes, IRegisterCheckReq>({
      query: ({ yearMonth }) => `check/${yearMonth}`,
      providesTags: (result, error, { yearMonth }) => [{ type: 'Register', id: 'check', yearMonth }],
    }),
    mission: builder.mutation<IRegisterMissionRes, IRegisterMissionReq>({
      query(body) {
        return {
          url: 'mission',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Register', id: 'mission' }],
    }),
    prot: builder.mutation<IRegisterProtRes, IRegisterProtReq>({
      query(body) {
        return {
          url: 'prot',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Register', id: 'prot' }],
    }),
  }),
});

export const { useUserMutation, useCheckQuery, useLazyCheckQuery, useMissionMutation, useProtMutation } = registerApi;
