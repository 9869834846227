import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import type { IUserState } from 'interfaces/user';
import { userApi } from 'services/user';
import { periodApi } from 'services/period';

const initialState: IUserState = {
  studId: process.env.NODE_ENV === 'development' ? '1986992' : '',
  grade: 3, // 현재 grade 정보
  yearMonth: dayjs().format('YYMM'), // 현재 yearMonth 정보
  name: (window.Android && JSON.parse(window.Android.getInitVariables()).nameKor) ?? '김홈런',
  token: null,
  lrnTypeGroupCD: 0,
  chlgGroupCD: 0,
  semester: parseInt(dayjs().format('MM')) <= 6 ? 1 : 2,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear: () => initialState,
    changeStudentInfo(
      state,
      action: PayloadAction<{ key: 'studId' | 'grade' | 'yearMonth' | 'name'; value: string | number }>,
    ) {
      if (typeof action.payload.value === 'number') {
        (state[action.payload.key] as number) = Number(action.payload.value);
      } else {
        (state[action.payload.key] as string) = action.payload.value;
      }
    },
    setUserState(state, action: PayloadAction<{ studId: string; name: string }>) {
      state.studId = action.payload.studId;
      state.name = action.payload.name;
    },
  },
  extraReducers: builder =>
    builder
      .addMatcher(userApi.endpoints.setToken.matchPending, () => {})
      .addMatcher(userApi.endpoints.setToken.matchFulfilled, (state, action) => {
        const { token, data } = action.payload;
        if (token) {
          state.token = token;
          localStorage.setItem('token', action.payload.token);
        }
        if (data) {
          const { grade, studNm } = data;
          state.grade = parseInt(grade);
          state.name = studNm;
        }
      })
      .addMatcher(userApi.endpoints.setToken.matchRejected, () => {})
      .addMatcher(periodApi.endpoints.root.matchPending, () => {})
      .addMatcher(periodApi.endpoints.root.matchFulfilled, (state, action) => {
        // yearMonth 갱신, 다음달로 넘어가는 경우 대비
        state.yearMonth = dayjs(action.payload.data.current.current_date).format('YYMM');
      })
      .addMatcher(periodApi.endpoints.root.matchRejected, () => {})
      .addMatcher(userApi.endpoints.getUser.matchPending, () => {})
      .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
        if ('studInfo' in action.payload.data) {
          const { LRN_TYPE_GROUP_CD, CHLG_GROUP_CD } = action.payload.data.studInfo[0];
          state.lrnTypeGroupCD = LRN_TYPE_GROUP_CD ?? 0;
          state.chlgGroupCD = CHLG_GROUP_CD;
        }
      })
      .addMatcher(userApi.endpoints.getUser.matchRejected, () => {})
      .addMatcher(periodApi.endpoints.getSemester.matchPending, () => {})
      .addMatcher(periodApi.endpoints.getSemester.matchFulfilled, (state, action) => {
        if (action.payload.data.semester) {
          state.semester = action.payload.data.semester as 1 | 2;
        }
      })
      .addMatcher(periodApi.endpoints.getSemester.matchRejected, () => {}),
});

export default userSlice;
