import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './App.css';
import globalSlice from 'store/reducers/global';
import Loading from 'components/common/Loading';
import PopupContainer from 'containers/PopupContainer';

//문제풀이결과 확인페이지, 혼자챌린지 시작전 알림팝업 추가
import ProblemResult from 'pages/ProblemResult';
import ChallengeStartPopup from 'pages/ChallengeStartPopup';

const TestPage = lazy(() => import('pages/TestPage'));
const InitPage = lazy(() => import('pages/InitPage'));
const ApplyPage = lazy(() => import('pages/ApplyPage'));
const ChallengePage = lazy(() => import('pages/ChallengePage'));
const AdminPage = lazy(() => import('pages/AdminPage'));
const ProblemPage = lazy(() => import('pages/ProblemPage'));
// const TestNav = lazy(() => import('components/TestNav'));
const PageNotFound = lazy(() => import('pages/404'));
const ProblemTestPage = lazy(() => import('pages/ProblemTestPage'));
const ProblemViewTestPage = lazy(() => import('pages/ProblemViewTestPage'));
const ProblemConsiderTestPage = lazy(() => import('pages/ProblemConsiderTestPage'));

declare global {
  interface Window {
    user: any;
    intent: any;
    sendBroad: any;
    Android: any;
    onBackPressed: any;
    onPause: any;
    onResume: any;
  }
}

const App = () => {
  const dispatch = useDispatch();

  // Android Back Button 막음
  if (window.Android) {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', e => {
      e.preventDefault();
      window.history.pushState(null, '', window.location.href);
    });

    window.onPause = () => {
      dispatch(globalSlice.actions.setSound(false));
    };
    window.onResume = () => {
      dispatch(globalSlice.actions.setSound(true));
    };
  }

  useEffect(() => {
    // ZTE 기기 해상도 대응
    try {
      if (navigator.userAgent.indexOf('ISE-T100C') > -1) {
        require('./assets/css/zte.css');
        let body = document.getElementsByTagName('body')[0];
        body.classList.add('zte');
        console.log('this is zte');
      } else {
        console.log('this is not zte');
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (document.getElementsByTagName('body')[0].classList.contains('admin')) {
      document.getElementsByTagName('body')[0].classList.remove('admin');
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div className="mathematical-cells">
          <Loading />
        </div>
      }>
      {/*{process.env.NODE_ENV === 'development' && <TestNav />}*/}
      <BrowserRouter>
        <Switch>
          {process.env.REACT_APP_PRODUCTION_TYPE === '2' && <Route path="/" component={TestPage} exact />}
          <Route path="/challenge" component={InitPage} exact />
          <Route path="/challenge/apply" component={ApplyPage} exact />
          <Route path="/challenge/main" component={ChallengePage} exact />
          <Route path="/challenge/admin" component={AdminPage} exact />
          <Route path="/challenge/problem" component={ProblemPage} exact />
          {process.env.REACT_APP_PRODUCTION_TYPE === '2' && (
            <Route path="/challenge/problem/test" component={ProblemTestPage} exact />
          )}
          {process.env.REACT_APP_PRODUCTION_TYPE === '2' && (
            <Route path="/challenge/problem/view/:problemId" component={ProblemViewTestPage} exact />
          )}
          {process.env.REACT_APP_PRODUCTION_TYPE === '2' && (
            <Route path="/challenge/problem/view/:problemId/:answer" component={ProblemViewTestPage} exact />
          )}
          {process.env.REACT_APP_PRODUCTION_TYPE === '2' && (
            <Route path="/challenge/problem/consider/:problemId/:answer" component={ProblemConsiderTestPage} exact />
          )}
          <Route path="/problemResult" component={ProblemResult} exact></Route>
          <Route path="/ChallengeStartPopup" component={ChallengeStartPopup} exact></Route>
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
      <PopupContainer />
    </Suspense>
  );
};

export default App;
