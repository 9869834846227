import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import { IPostCheerReq, IPostCheerRes, IGetCheerRes, IPatchCheerRes } from 'interfaces/cheer';

export const cheerApi = createApi({
  reducerPath: 'cheerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/cheer/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Cheer'],
  endpoints: builder => ({
    postCheer: builder.mutation<IPostCheerRes, IPostCheerReq>({
      query(body) {
        return {
          url: '',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Cheer', id: 'postCheer' }],
    }),
    getCheer: builder.query<IGetCheerRes, void>({
      query: () => '',
      providesTags: () => [{ type: 'Cheer', id: 'getCheer' }],
    }),
    patchCheer: builder.mutation<IPatchCheerRes, void>({
      query(body) {
        return {
          url: '',
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Cheer', id: 'patchCheer' }],
    }),
  }),
});

export const { usePostCheerMutation, useGetCheerQuery, useLazyGetCheerQuery, usePatchCheerMutation } = cheerApi;
