import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { IRootState } from 'store/store';
import popupSlice from 'store/reducers/popup';

const ToastPopup = () => {
  const dispatch = useDispatch();

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { isOpen, message } = useSelector(
    ({ popup }: IRootState) => ({
      isOpen: popup.isOpen,
      message: popup.message,
    }),
    shallowEqual,
  );

  useEffect(() => {
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  useState(() => {
    const timeout = setTimeout(() => {
      dispatch(popupSlice.actions.clear());
    }, 1200);
    setTimeoutId(timeout);
  });
  return (
    <>
      {/* <!-- ####공통 팝업 --> */}
      {/* <!-- js 팝업 | act-on: 생성 / z-index 값 넣어주세요 --> */}
      <div className={clsx('share-toast-pop-ui', { 'act-on': isOpen })}>
        {/* <!-- ###토스트 팝업 --> */}
        {/* <!-- js 토스트 팝업 @1 또는 @2으로 사용 | 보임: @1 act-show + 사라짐: act-hidden / @2 보이고 사라짐: act-once --> */}
        {/* <!-- 박스 라운드 없는 버전 box-ty2 추가 --> */}
        <div className="share-toast-box box-ty2 act-show">
          <div className="share-txt-area">
            <p className="share-txt">{message}</p>
          </div>
        </div>
        {/* <!-- //###토스트 팝업 --> */}
      </div>
      {/* <!-- //####공통 팝업 --> */}
    </>
  );
};

export default ToastPopup;
