import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { registerApi } from 'services/register';
import type { IApplyState } from 'interfaces/apply';

const initialState: IApplyState = {
  lineComplete: false, // 인트로 완료
  loadingComplete: false, // 로딩 완료
  applyPopup: false, // 챌린지 신청하기 팝업 열림 여부
  signal: 0, // 0: 등록 가능, 1: 등록된 회원, 2: 등록 불가(진단 필요)
  diagnosticsComplete: false, // 진단 완료
  challengeStart: false, // 챌린지 학습기간 여부
  challengeResult: false, // 챌린지 결과기간 여부
  fromScanning: false, // 진단 검사 페이지에서 왔는지 여부
};

const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    clear: () => initialState,
    changeApplyState(
      state,
      action: PayloadAction<{
        key:
          | 'lineComplete'
          | 'loadingComplete'
          | 'applyPopup'
          | 'diagnosticsComplete'
          | 'challengeStart'
          | 'challengeResult'
          | 'fromScanning';
        value: boolean;
      }>,
    ) {
      state[action.payload.key] = action.payload.value;
    },
    changeSignal(state, action: PayloadAction<{ value: number }>) {
      state.signal = action.payload.value;
    },
  },
  extraReducers: builder =>
    builder
      .addMatcher(registerApi.endpoints.check.matchPending, () => {})
      .addMatcher(registerApi.endpoints.check.matchFulfilled, (state, action) => {
        const { signal } = action.payload;
        state.signal = signal;
        state.diagnosticsComplete = signal !== 2;
      })
      .addMatcher(registerApi.endpoints.check.matchRejected, () => {}),
});

export default initSlice;
