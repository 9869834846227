import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import {
  IGetUserRes,
  ISetTokenReq,
  ISetTokenRes,
  IGetGroupRes,
  IGetGroupReq,
  IGetUserWithYearMonthReq,
} from 'interfaces/user';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/user/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['User'],
  endpoints: builder => ({
    setToken: builder.mutation<ISetTokenRes, ISetTokenReq>({
      query(body) {
        return {
          url: 'setToken',
          method: 'POST',
          body,
          headers: {},
        };
      },
      invalidatesTags: () => [{ type: 'User', id: 'setToken' }],
    }),
    getUser: builder.query<IGetUserRes, void>({
      query: () => 'getUser',
      providesTags: () => [{ type: 'User', id: 'getUser' }],
    }),
    getUserWidthYearMonth: builder.query<IGetUserRes, IGetUserWithYearMonthReq>({
      query: ({ yearMonth }) => `getUser/${yearMonth}`,
      providesTags: () => [{ type: 'User', id: 'getUserWidthYearMonth' }],
    }),
    getGroup: builder.query<IGetGroupRes, IGetGroupReq>({
      query: ({ yearMonth, groupCD }) => `getGroup/${yearMonth}/${groupCD}`,
      providesTags: () => [{ type: 'User', id: 'getGroup' }],
    }),
  }),
});

export const {
  useSetTokenMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyGetUserWidthYearMonthQuery,
  useGetGroupQuery,
} = userApi;
