import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import { IGetMissionRes } from 'interfaces/mission';

export const missionApi = createApi({
  reducerPath: 'missionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/mission/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Mission'],
  endpoints: builder => ({
    getMission: builder.query<IGetMissionRes, void>({
      query: () => 'getMission',
      providesTags: () => [{ type: 'Mission', id: 'getMission' }],
    }),
  }),
});

export const { useGetMissionQuery } = missionApi;
