import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { mycellApi } from 'services/mycell';
import { userApi } from 'services/user';
import { IAdminState } from 'interfaces/admin';

const initialState: IAdminState = {
  adminYear: dayjs().format('YY').toString(),
  adminMonth: dayjs().format('MM').toString(),
  yearMonthData: {},
  adminStudStatusDetail: 'L', // L: 일반, P: 참여 중단
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clear: () => initialState,
    setYearMonth: (state, action: PayloadAction<{ year: string; month: string }>) => {
      const { year, month } = action.payload;
      state.adminYear = year;
      state.adminMonth = month;
    },
  },
  extraReducers: builders =>
    builders
      .addMatcher(mycellApi.endpoints.lrrYearlyList.matchPending, () => {})
      .addMatcher(mycellApi.endpoints.lrrYearlyList.matchFulfilled, (state, action) => {
        const year = dayjs().format('YY').toString();
        const month = dayjs().format('MM').toString();

        if (!(year in action.payload.data)) {
          // 현재 year: 없음, month: 없음
          state.yearMonthData = { ...action.payload.data, [year]: [month] };
        } else if (
          year in action.payload.data &&
          !action.payload.data[dayjs().format('YY')].find(item => item === month)
        ) {
          // 현재 year: 있음, month: 없음
          state.yearMonthData = { ...action.payload.data, [year]: [...action.payload.data[year], month] };
        } else {
          // 현재 year: 있음, month: 있음
          state.yearMonthData = action.payload.data;
        }
      })
      .addMatcher(mycellApi.endpoints.lrrYearlyList.matchRejected, () => {})
      .addMatcher(userApi.endpoints.getUserWidthYearMonth.matchPending, () => {})
      .addMatcher(userApi.endpoints.getUserWidthYearMonth.matchFulfilled, (state, action) => {
        if ('studInfo' in action.payload.data) {
          const { STUD_STATUS_DETAIL } = action.payload.data.studInfo[0];
          state.adminStudStatusDetail = STUD_STATUS_DETAIL;
        }
      })
      .addMatcher(userApi.endpoints.getUserWidthYearMonth.matchRejected, () => {}),
});

export default adminSlice;
