import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { proxyUrlPath } from 'services';
import {
  IGetUserDataReq,
  IGetUserDataRes,
  ISetTeamChallengeStatusDetailReq,
  ISetTeamChallengeStatusDetailRes,
} from 'interfaces/admin';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: proxyUrlPath + '/admin/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Admin'],
  endpoints: builder => ({
    getMathCellStudInfo: builder.query<IGetUserDataRes, IGetUserDataReq>({
      query: ({ stud_id, grade, yymm, stud_nm }) => ({
        url: 'getMathCellStudInfo',
        method: 'GET',
        params: {
          stud_id,
          grade,
          yymm,
          stud_nm,
        },
      }),
      providesTags: () => [{ type: 'Admin', id: 'getMathCellStudInfo' }],
    }),
    setTeamChallengeStatusDetail: builder.mutation<ISetTeamChallengeStatusDetailRes, ISetTeamChallengeStatusDetailReq>({
      query(body) {
        return {
          url: 'setTeamChallengeStatusDetail',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Admin', id: 'setTeamChallengeStatusDetail' }],
    }),
  }),
});

export const { useLazyGetMathCellStudInfoQuery, useSetTeamChallengeStatusDetailMutation } = adminApi;
