import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IPopupState } from 'interfaces/popup';

const initialState: IPopupState = {
  type: null,
  isOpen: false,
  message: '',
  callbackOk: null,
  callbackYes: null,
  callbackNo: null,
  btnOkMessage: null,
  btnYesMessage: null,
  btnNoMessage: null,
  myCellPopup: false, // MY세포 팝업 열림 여부
  isProblemPopup: false,
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    clear: () => initialState,
    openAlertPopup(
      state,
      action: PayloadAction<
        | {
            message: string | JSX.Element;
            callbackOk: () => void;
            btnOkMessage: string;
            isProblemPopup?: boolean;
          }
        | {
            message: string | JSX.Element;
            callbackOk: () => void;
            isProblemPopup?: boolean;
          }
      >,
    ) {
      const { message, callbackOk, isProblemPopup } = action.payload;

      state.type = 'alert';
      state.isOpen = true;
      state.message = message;
      if (typeof isProblemPopup === 'boolean') {
        state.isProblemPopup = isProblemPopup;
      }
      state.callbackOk = callbackOk;
      if ('btnOkMessage' in action.payload) {
        state.btnOkMessage = action.payload.btnOkMessage;
      }
    },
    openConfirmPopup(
      state,
      action: PayloadAction<
        | {
            message: string | JSX.Element;
            callbackYes: () => void;
            callbackNo: () => void;
            btnYesMessage: string | null;
            btnNoMessage: string | null;
          }
        | {
            message: string | JSX.Element;
            callbackYes: () => void;
            callbackNo: () => void;
            btnYesMessage: string | null;
          }
        | {
            message: string | JSX.Element;
            callbackYes: () => void;
            callbackNo: () => void;
            btnNoMessage: string | null;
          }
        | {
            message: string | JSX.Element;
            callbackYes: () => void;
            callbackNo: () => void;
          }
      >,
    ) {
      const { message, callbackYes, callbackNo } = action.payload;

      state.type = 'confirm';
      state.isOpen = true;
      state.message = message;
      state.callbackYes = callbackYes;
      state.callbackNo = callbackNo;
      if ('btnYesMessage' in action.payload) {
        state.btnYesMessage = action.payload.btnYesMessage;
      }
      if ('btnNoMessage' in action.payload) {
        state.btnNoMessage = action.payload.btnNoMessage;
      }
    },
    openToastPopup(state, action: PayloadAction<{ message: string | JSX.Element }>) {
      state.type = 'toast';
      state.isOpen = true;
      state.message = action.payload.message;
    },
    setMyCellPopup(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.myCellPopup = action.payload.isOpen;
    },
  },
});

export default popupSlice;
