import 'assets/css/challenge_main.css';
import '../assets/css/ProblemResult.css'

const ProblemResult = () => {
    return(
        <div className="mathematical-cells">
            <div className="challenge-main">
                <header class="skin-top">
                    <div class="header-2">
                        <div class="top-title">
                            <h1 class="title-main title-math-cells">수학의 세포들</h1>
                            <h2 class="title-sub">문제 풀이 결과</h2>
                        </div>
                        <div class="top-close">
                            <button type="button" name="button" class="btn-close">
                                <i class="ico i-top-close-2">닫기</i>
                            </button>
                        </div>                        
                    </div>
                </header>
                <div className="skin-main problem-result">
                    <div className="skin-main-wrap">
                        <ul className="problem-result__box problem-result__problem">
                            <li>
                                {/* 정답일경우 result-o, 오답일 경우 result-x*/}
                                <span className='result-o'>1</span>
                                <div><b>직사각형, 정사각형, 넓이</b></div>
                            </li>
                            <li>
                                <span className='result-x'>2</span>
                                <div><b>직사각형, 정사각형, 넓이</b></div>
                            </li>
                            <li>
                                <span className='result-o'>3</span>
                                <div><b>직사각형, 정사각형, 넓이</b></div>
                            </li>
                            <li>
                                <span className='result-x'>4</span>
                                <div><b>평행사변형, 넓이</b></div>
                            </li>
                            <li>
                                <span className='result-o'>5</span>
                                <div><b>평행사변형, 넓이</b></div>
                            </li>
                        </ul>
                        <ul className="problem-result__box problem-result__total">
                            <li>
                                <span>맞힌 문제 수</span>
                                <div className='totalBox__correct'>
                                    <span><b>4</b>/5</span>
                                </div>
                            </li>
                            <li>
                                <span>공부 시간</span>
                                <div className='totalBox__time'>
                                    <span><b>1시간 30분</b></span>
                                </div>
                            </li>
                            <li>
                                <span className='background__green'>음표 획득</span>
                                <div className='totalBox__note'>
                                    <span><b>+1</b></span>
                                </div>
                            </li>
                        </ul>
                        <div className="problem-result__box problem-result__btnWrap">
                            <button className="btn btn_commentary">해설 보기</button>
                            <button className="btn btn_finish">학습 완료</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    )
};

export default ProblemResult;