import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IGetNoteInfoRes } from 'interfaces/challenge';
import { proxyUrlPath } from 'services';

export const aloneChallengeApi = createApi({
  reducerPath: 'aloneChallengeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: proxyUrlPath + '/alone_challenge/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['AloneChallenge'],
  endpoints: builder => ({
    getNoteInfo: builder.query<IGetNoteInfoRes, void>({
      query: () => 'getNoteInfo',
      providesTags: () => [{ type: 'AloneChallenge', id: 'getNoteInfo' }],
    }),
  }),
});

export const { useGetNoteInfoQuery } = aloneChallengeApi;
