import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import {
  ICellsReq,
  IViewCellsRes,
  ILrrCellsRes,
  IPredMockRes,
  ILrrYearlyListRes,
  ILrrYearlyListReq,
} from 'interfaces/mycell';

export const mycellApi = createApi({
  reducerPath: 'mycellApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/mycell/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Mycell'],
  endpoints: builder => ({
    viewCells: builder.query<IViewCellsRes, ICellsReq>({
      query: ({ studId, grade, yearMonth }) => `viewCells/${studId}/${grade}/${yearMonth}`,
      providesTags: () => [{ type: 'Mycell', id: 'viewCells' }],
    }),
    lrrCells: builder.query<ILrrCellsRes, ICellsReq>({
      query: ({ studId, grade, yearMonth }) => `lrrCells/${studId}/${grade}/${yearMonth}`,
      providesTags: () => [{ type: 'Mycell', id: 'lrrCells' }],
    }),
    predMock: builder.query<IPredMockRes, ICellsReq>({
      query: ({ studId, grade, yearMonth }) => `predMock/${studId}/${grade}/${yearMonth}`,
      providesTags: () => [{ type: 'Mycell', id: 'predMock' }],
    }),
    lrrYearlyList: builder.query<ILrrYearlyListRes, ILrrYearlyListReq>({
      query: ({ studId }) => `lrrYearlyList/${studId}`,
      providesTags: () => [{ type: 'Mycell', id: 'lrrYearlyList' }],
    }),
  }),
});

export const {
  useViewCellsQuery,
  useLrrCellsQuery,
  useLazyPredMockQuery,
  useLrrYearlyListQuery,
  useLazyLrrYearlyListQuery,
} = mycellApi;
