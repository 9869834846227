import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGlobalState } from 'interfaces/global';

const initialState: IGlobalState = {
  globalSound: true,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    clear: () => initialState,
    setSound: (state, action: PayloadAction<boolean>) => {
      state.globalSound = action.payload;
    },
  },
});

export default globalSlice;
