import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import {
  IProblemCheckReq,
  IProblemCheckRes,
  IViewProblemReq,
  IViewProblemRes,
  IConsiderProblemRes,
  IConsiderProblemReq,
} from 'interfaces/problem';

export const problemApi = createApi({
  reducerPath: 'problemApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/problem/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: false,
  refetchOnReconnect: true,
  tagTypes: ['Problem'],
  endpoints: builder => ({
    view: builder.query<IViewProblemRes, IViewProblemReq>({
      query: ({ seq_no, problem_id, analysis }) => `view/${seq_no}/${problem_id}/${analysis}`,
      providesTags: () => [{ type: 'Problem', id: 'viewProblem' }],
    }),
    consider: builder.query<IConsiderProblemRes, IConsiderProblemReq>({
      query: ({ seq_no, problem_id, ansinput, analysis }) =>
        `consider/${seq_no}/${problem_id}/${encodeURIComponent(ansinput)}/${analysis}`,
      providesTags: () => [{ type: 'Problem', id: 'viewProblem' }],
    }),
    checkProblems: builder.mutation<IProblemCheckRes, IProblemCheckReq>({
      query(body) {
        return {
          url: 'check',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});
export const { useViewQuery, useConsiderQuery, useCheckProblemsMutation } = problemApi;
