import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import { IResultMessageReq, IResultMessageRes, IResultReq, IResultRes } from 'interfaces/result';

export const resultApi = createApi({
  reducerPath: 'resultApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/result/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Result'],
  endpoints: builder => ({
    root: builder.query<IResultRes, IResultReq>({
      query: ({ studId, yearMonth }) => `${studId}/${yearMonth}`,
      providesTags: () => [{ type: 'Result', id: 'root' }],
    }),
    message: builder.query<IResultMessageRes, IResultMessageReq>({
      query: ({ studId, yearMonth }) => `message/${studId}/${yearMonth}`,
      providesTags: () => [{ type: 'Result', id: 'message' }],
    }),
  }),
});

export const { useRootQuery, useLazyRootQuery, useMessageQuery, useLazyMessageQuery } = resultApi;
