import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { urlPath } from 'services';
import { IGetSemesterReq, IGetSemesterRes, IPeriodReq, IPeriodRes } from 'interfaces/period';

export const periodApi = createApi({
  reducerPath: 'periodApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlPath + '/period/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Period'],
  endpoints: builder => ({
    root: builder.query<IPeriodRes, void>({
      query: () => '',
      providesTags: () => [{ type: 'Period', id: 'root' }],
    }),
    periodWithYearMonth: builder.query<IPeriodRes, IPeriodReq>({
      query: ({ yearMonth }) => yearMonth,
      providesTags: () => [{ type: 'Period', id: 'periodWithYearMonth' }],
    }),
    getSemester: builder.query<IGetSemesterRes, IGetSemesterReq>({
      query: ({ yearMonth }) => `getSemester/${yearMonth}`,
      providesTags: () => [{ type: 'Period', id: 'getSemester' }],
    }),
  }),
});

export const { useRootQuery, useGetSemesterQuery } = periodApi;
