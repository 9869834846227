import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { IRootState } from 'store/store';
import popupSlice from 'store/reducers/popup';

const ConfirmPopup = () => {
  const dispatch = useDispatch();

  const { isOpen, message, callbackYes, callbackNo, btnYesMessage, btnNoMessage } = useSelector(
    ({ popup }: IRootState) => ({
      isOpen: popup.isOpen,
      message: popup.message,
      callbackYes: popup.callbackYes,
      callbackNo: popup.callbackNo,
      btnYesMessage: popup.btnYesMessage,
      btnNoMessage: popup.btnNoMessage,
    }),
    shallowEqual,
  );

  const onClickYes = useCallback(() => {
    if (callbackYes) {
      callbackYes();
    }
    dispatch(popupSlice.actions.clear());
  }, [callbackYes]);

  const onClickNo = useCallback(() => {
    if (callbackNo) {
      callbackNo();
    }
    dispatch(popupSlice.actions.clear());
  }, [callbackNo]);

  return (
    // {/* <!-- ####Confirm --> */}
    // {/* <!-- js 활성화 open 추가 / z-index값 넣어주세요--> */}
    <div className={clsx('share-pop-ui', { open: isOpen })} style={{ zIndex: 201 }}>
      <span className="dim"></span>
      <div className="dp-table  pos-a">
        <div className="dp-cell">
          {/* <!-- ##확인 박스 --> */}
          <div className="share-confirm-box">
            <div className="share-txt-area">
              <div className="share-txt">
                <div className="dp-table">
                  <div className="dp-cell">
                    <p>{message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="share-btn-area">
              <ul>
                <li className="share-btn btn-o">
                  <button className="clickable" onClick={onClickYes}>
                    {btnYesMessage ?? '예'}
                  </button>
                </li>
                <li className="share-btn btn-x">
                  <button className="clickable" onClick={onClickNo}>
                    {btnNoMessage ?? '아니오'}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- ##확인 박스 --> */}
        </div>
      </div>
    </div>
    // {/* <!-- ####Confirm --> */}
  );
};

export default ConfirmPopup;
