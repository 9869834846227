import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { userApi } from 'services/user';
import { aloneChallengeApi } from 'services/aloneChallenge';
import { mycellApi } from 'services/mycell';
import { lrrApi } from 'services/lrr';
import { resultApi } from 'services/result';
import type { IChallengeState } from 'interfaces/challenge';

const initialState: IChallengeState = {
  mcStudType: 0, // 학습유형 (0: unknown, 1: 함께챌린지, 2: 혼자챌린지)
  studStatusDetail: 'L', // L: 일반, P: 참여 중단
  totalMissionCnt: 16, // 총 학습 수 (max: 16, min: 1)
  completeMissionCnt: 0, // 학습 완료 수
  todayMissionCnt: 0, // 오늘 완료한 학습 수 (0: 기본, 1: 미션 1개 완료, 2: 오늘의 학습 완료)
  todayNoteCnt: 0, // 오늘 획득한 음표 수
  group: {
    totalToBeSolvedQuestionNumb: 0,
    totalSolvedQuestionNumb: 0,
    totalToBeAcqNoteNumb: 0, // 팀에서 획득해야 할 음표 수
    totalAcqNoteNumb: 0, // 팀에서 획득한 음표 수
    groupData: [],
  },
  challengeSuccess: false, // 챌린지 성공 여부
  mvp: false, // 현재 챌린지의 mvp 여부
  totalProgress: 0, // 팀 달성률
  myProgress: 0, // 개인 달성률
  totalAcqNoteProgress: 0, // 팀 음표 획득률
  cheer: [], // 보낸 응원 메시지 (받은 응원 메시지 X)
  myCellTab: false,
  myCellYear: dayjs().format('YY').toString(),
  myCellMonth: dayjs().format('MM').toString(),
  yearMonthData: {},
  guidePopup: false, // 챌린지 가이드 팝업 열림 여부
  alarmPopup: false, // 알림 팝업 열림 여부
  mvpPopup: false, // mvp 팝업 열림 여부
  yearsList: [],
  selectedYearsIndex: -1,
  monthList: [],
  selectedMonthIndex: -1,
  lrrCells: null,
};

const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    clear: () => initialState,
    changeState(
      state,
      action: PayloadAction<{
        key:
          | 'mcStudType'
          | 'totalMissionCnt'
          | 'completeMissionCnt'
          | 'todayMissionCnt'
          | 'selectedYearsIndex'
          | 'selectedMonthIndex';
        value: number;
      }>,
    ) {
      state[action.payload.key] = action.payload.value;
    },
    addCheer(state, action: PayloadAction<{ studId: number; type: number; homevatar: string }>) {
      state.cheer = [...state.cheer, { ...action.payload }];
    },
    deleteCheer(state, action: PayloadAction<{ studId: number; type: number }>) {
      state.cheer = state.cheer.filter(
        cheer => !(cheer.studId === action.payload.studId && cheer.type === action.payload.type),
      );
    },
    clearCheer(state) {
      state.cheer = [];
    },
    setMyCellTab: (state, action: PayloadAction<boolean>) => {
      state.myCellTab = action.payload;
    },
    setYearMonth: (state, action: PayloadAction<{ year: string; month: string }>) => {
      const { year, month } = action.payload;
      state.myCellYear = year;
      state.myCellMonth = month;
    },
    setList: (state, action: PayloadAction<{ key: 'yearsList' | 'monthList'; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    changePopup(
      state,
      action: PayloadAction<{
        key: 'guidePopup' | 'alarmPopup' | 'mvpPopup';
        value: boolean;
      }>,
    ) {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: builders =>
    builders
      .addMatcher(userApi.endpoints.setToken.matchPending, () => {})
      .addMatcher(userApi.endpoints.setToken.matchFulfilled, (state, action) => {
        state.mcStudType = action.payload.data.mcStudType;
      })
      .addMatcher(userApi.endpoints.setToken.matchRejected, () => {})
      .addMatcher(userApi.endpoints.getUser.matchPending, () => {})
      .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
        if ('studInfo' in action.payload.data) {
          const { MC_STUD_TYPE, STUD_STATUS_DETAIL } = action.payload.data.studInfo[0];
          state.mcStudType = MC_STUD_TYPE;
          state.studStatusDetail = STUD_STATUS_DETAIL;
        }
      })
      .addMatcher(userApi.endpoints.getUser.matchRejected, () => {})
      .addMatcher(userApi.endpoints.getGroup.matchPending, () => {})
      .addMatcher(userApi.endpoints.getGroup.matchFulfilled, (state, action) => {
        const data = action.payload.data;
        const {
          // totalSolvedQuestionNumb,
          // totalToBeSolvedQuestionNumb,
          totalToBeAcqNoteNumb,
          totalAcqNoteNumb,
          groupData,
        } = data;

        state.group = data;
        // state.challengeSuccess = totalSolvedQuestionNumb >= totalToBeSolvedQuestionNumb;
        state.challengeSuccess = totalAcqNoteNumb >= totalToBeAcqNoteNumb;
        // state.totalProgress =
        //   totalSolvedQuestionNumb > totalToBeSolvedQuestionNumb
        //     ? 100
        //     : totalToBeSolvedQuestionNumb > 0
        //     ? Math.floor((totalSolvedQuestionNumb / totalToBeSolvedQuestionNumb) * 100)
        //     : 0;
        state.totalAcqNoteProgress =
          totalAcqNoteNumb > totalToBeAcqNoteNumb
            ? 100
            : totalToBeAcqNoteNumb > 0
            ? Math.floor((totalAcqNoteNumb / totalToBeAcqNoteNumb) * 100)
            : 0;

        // const myData = groupData.find(data => data.isMe);
        // if (myData) {
        //   state.myProgress =
        //     ((myData.addedQuestionNumb > 0
        //       ? myData.solvedQuestionNumb - myData.addedQuestionNumb
        //       : myData.solvedQuestionNumb) /
        //       myData.toBeSolvedQuestionNumb) *
        //     100;
        // }

        const myData = groupData.find(data => data.isMe);
        if (myData) {
          state.myProgress =
            ((myData.addedNoteNumb > 0 
              ? myData.acqNoteNumb - myData.addedNoteNumb 
              : myData.acqNoteNumb) /
              myData.toBeAcqNoteNumb) *
            100;
        }
      })
      .addMatcher(userApi.endpoints.getGroup.matchRejected, () => {})
      .addMatcher(aloneChallengeApi.endpoints.getNoteInfo.matchPending, () => {})
      .addMatcher(aloneChallengeApi.endpoints.getNoteInfo.matchFulfilled, (state, action) => {
        const { total_mission_cnt, complete_lrr_cnt, status } = action.payload;
        if (status === '200') {
          state.totalMissionCnt = total_mission_cnt;
          state.completeMissionCnt = complete_lrr_cnt;
        }
      })
      .addMatcher(aloneChallengeApi.endpoints.getNoteInfo.matchRejected, () => {})
      .addMatcher(mycellApi.endpoints.lrrYearlyList.matchPending, () => {})
      .addMatcher(mycellApi.endpoints.lrrYearlyList.matchFulfilled, (state, action) => {
        // if (!!state.mcStudType) {
        //   const year = dayjs().format('YY').toString();
        //   const month = dayjs().format('MM').toString();

        //   if (!(year in action.payload.data)) {
        //     // 현재 year: 없음, month: 없음
        //     state.yearMonthData = { ...action.payload.data, [year]: [month] };
        //   } else if (
        //     year in action.payload.data &&
        //     !action.payload.data[dayjs().format('YY')].find(item => item === month)
        //   ) {
        //     // 현재 year: 있음, month: 없음
        //     state.yearMonthData = { ...action.payload.data, [year]: [...action.payload.data[year], month] };
        //   } else {
        //     // 현재 year: 있음, month: 있음
        //     state.yearMonthData = action.payload.data;
        //   }
        // } else {
        state.yearMonthData = action.payload.data;
        // }
      })
      .addMatcher(mycellApi.endpoints.lrrYearlyList.matchRejected, () => {})
      .addMatcher(mycellApi.endpoints.lrrCells.matchPending, () => {})
      .addMatcher(mycellApi.endpoints.lrrCells.matchFulfilled, (state, action) => {
        action.payload.data.nodes.map(value => {
          if (value.todayLRR) {
            state.lrrCells = value;
          }
        });
      })
      .addMatcher(mycellApi.endpoints.lrrCells.matchRejected, () => {})
      .addMatcher(lrrApi.endpoints.checkLrr.matchPending, () => {})
      .addMatcher(lrrApi.endpoints.checkLrr.matchFulfilled, (state, action) => {
        const { todayNumberExecutions, todayNoteExecutions } = action.payload.data;
        state.todayMissionCnt = todayNumberExecutions;
        state.todayNoteCnt = todayNoteExecutions;
      })
      .addMatcher(lrrApi.endpoints.checkLrr.matchRejected, () => {})
      .addMatcher(resultApi.endpoints.root.matchPending, () => {})
      .addMatcher(resultApi.endpoints.root.matchFulfilled, (state, action) => {
        if (action.payload.data && action.payload.data.isUserMVP) {
          state.mvp = true;
        }
      })
      .addMatcher(resultApi.endpoints.root.matchRejected, () => {}),
});

export default challengeSlice;
