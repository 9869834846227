import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { proxyUrlPath } from 'services';
import { IGetLearningResultReq, IGetLearningResultRes } from 'interfaces/problem';

export const getLearningResultApi = createApi({
  reducerPath: 'getLearningResultApi',
  baseQuery: fetchBaseQuery({
    baseUrl: proxyUrlPath + '/getLearningResult',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      process.env.NODE_ENV === 'development' && headers.set('ngrok-skip-browser-warning', '109');
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('token', token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['LearningResult'],
  endpoints: builder => ({
    getLearningResult: builder.query<IGetLearningResultRes, IGetLearningResultReq>({
      query: ({ lrr_code }) => `?lrr_code=${lrr_code}`,
      providesTags: () => [{ type: 'LearningResult', id: 'getLearningResult' }],
    }),
  }),
});

export const { useLazyGetLearningResultQuery } = getLearningResultApi;
