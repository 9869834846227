import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { IRootState } from 'store/store';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import ToastPopup from 'components/common/popup/ToastPopup';

const PopupContainer = () => {
  const { type } = useSelector(
    ({ popup }: IRootState) => ({
      type: popup.type,
    }),
    shallowEqual,
  );

  switch (type) {
    case 'alert':
      return <AlertPopup />;
    case 'confirm':
      return <ConfirmPopup />;
    case 'toast':
      return <ToastPopup />;
    default:
      return <></>;
  }
};

export default PopupContainer;
