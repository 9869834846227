import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { lrrApi } from 'services/lrr';
import { problemApi } from 'services/problem';
import type { IProblem, IProblemsState, IProblemPopupDetail } from 'interfaces/problem';

const initialState: IProblemsState = {
  phase: 0,
  lrrCode: '',
  selectedProblemNumber: 0,
  delay: true,
  timer: false,
  totalElapsedTime: 0,
  keyPadType: 1,
  showSketchBook: false,
  problemPopupDetail: null,
  video: '',
  data: [],
  ansCheck: [] as IProblem[],
  firstAnsCheck: [] as IProblem[],
  activeInput: null,
  currentQuestionRef: null,
  isRendering: [],
};

const problemsSlice = createSlice({
  name: 'problems',
  initialState,
  reducers: {
    clear: () => initialState,
    changeProblemsState(
      state,
      action: PayloadAction<{
        key:
          | 'phase'
          | 'lrrCode'
          | 'selectedProblemNumber'
          | 'delay'
          | 'keyPadType'
          | 'video'
          | 'showSketchBook'
          | 'firstAnsCheck'
          | 'data'
          | 'timer'
          | 'totalElapsedTime';
        value: string | number | boolean | IProblem[];
      }>,
    ) {
      if (typeof action.payload.value === 'number') {
        (state[action.payload.key] as number) = Number(action.payload.value);
      } else if (typeof action.payload.value === 'string') {
        (state[action.payload.key] as string) = action.payload.value;
      } else if (typeof action.payload.value === 'boolean') {
        (state[action.payload.key] as boolean) = Boolean(action.payload.value);
      } else {
        (state[action.payload.key] as IProblem[]) = action.payload.value;
      }
    },
    changeProblemAnswer(state, action: PayloadAction<{ userInput: string; index: number }>) {
      state.data[action.payload.index].u_ans_input = action.payload.userInput;
    },
    changeProblemTime(state, action: PayloadAction<{ u_elapsed_time: string; index: number }>) {
      state.data[action.payload.index].u_elapsed_time = action.payload.u_elapsed_time;
    },
    changeActiveInput(state, action: PayloadAction<{ input: null | HTMLInputElement }>) {
      state.activeInput = action.payload.input;
    },
    changeProblemId(state, action: PayloadAction<{ index: number; id: string }>) {
      state.data[action.payload.index].f_problem_id = action.payload.id;
    },
    changeCurrentQuestionRef(state, action: PayloadAction<{ ref: HTMLDivElement | null }>) {
      state.currentQuestionRef = action.payload.ref;
    },
    changeIsRenderingArray(state, action: PayloadAction<{ array: boolean[] }>) {
      state.isRendering = action.payload.array;
    },
    changeRenderingDone(state, action: PayloadAction<{ index: number }>) {
      state.isRendering[action.payload.index] = true;
    },
    changeProblemPopupDetail: (
      state,
      action: PayloadAction<{
        data: IProblemPopupDetail | null;
      }>,
    ) => {
      state.problemPopupDetail = action.payload.data;
    },
  },
  extraReducers: builder =>
    builder
      .addMatcher(lrrApi.endpoints.generateProblems.matchPending, () => {})
      .addMatcher(lrrApi.endpoints.generateProblems.matchFulfilled, (state, action) => {
        state.lrrCode = action.payload.data.lrrCode;
        state.data = action.payload.data.lrrData;
      })
      .addMatcher(lrrApi.endpoints.generateProblems.matchRejected, () => {})
      .addMatcher(problemApi.endpoints.checkProblems.matchPending, () => {})
      .addMatcher(problemApi.endpoints.checkProblems.matchFulfilled, (state, action) => {
        state.ansCheck = action.payload.data;
        if (state.phase === 0) {
          state.firstAnsCheck = action.payload.data;
        }
        // data의 u_is_right 변경
        action.payload.data.forEach((data, idx) => {
          state.data[idx].u_is_right = data.u_is_right;
        });
      })
      .addMatcher(problemApi.endpoints.checkProblems.matchRejected, () => {})
      .addMatcher(lrrApi.endpoints.retryProblems.matchRejected, () => {})
      .addMatcher(lrrApi.endpoints.retryProblems.matchPending, () => {})
      .addMatcher(lrrApi.endpoints.retryProblems.matchFulfilled, (state, action) => {
        state.lrrCode = action.payload.data.lrrCode;
        state.data = action.payload.data.lrrData;
        state.ansCheck = action.payload.data.lrrData;
      }),
});

export default problemsSlice;
